:root {
  --nb-darkteal-a: #193741;
  --nb-darkteal-b: #094256;
  --nb-darkteal-c: #316272;
  --nb-teal-a: #148894;
  --nb-teal-b: #004f60;
  --nb-lightteal-a: #aedfe0;
  --nb-lightteal-b: #c1eae9;
  --nb-yellow: #fcde7c;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background: linear-gradient(-45deg, var(--nb-darkteal-a) 0%, var(--nb-darkteal-b) 50%, var(--nb-darkteal-c) 100%);
  color: var(--nb-darkteal-a);
  font-family: Roboto, sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.6;
}

a {
  color: inherit;
}

img.logo-img {
  width: 260px;
}

#header nav {
  max-width: 1200px;
  width: 100%;
  color: var(--nb-lightteal-b);
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 0 20px;
  display: flex;
}

nav ul {
  flex-direction: row;
  padding: 0;
  list-style: none;
  transition: all .4s;
  display: flex;
}

#header nav ul li {
  padding: 10px;
}

#header nav ul li:last-child {
  padding-right: 0;
}

hr.nb-line {
  border-top: 4px solid;
  border-color: var(--nb-lightteal-b);
  width: 140px;
  margin-top: 4px;
  margin-bottom: 4px;
}

hr.nb-line.dark {
  border-color: var(--nb-teal-b);
}

hr.nb-line.yellow {
  border-color: var(--nb-yellow);
}

#header nav a {
  color: var(--nb-lightteal-b);
  text-decoration: none;
}

#header {
  height: 100px;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

h1 {
  margin: .67em 0;
  font-size: 1.6em;
}

.bg-dark {
  background: linear-gradient(-45deg, var(--nb-darkteal-a) 0%, var(--nb-darkteal-b) 50%, var(--nb-darkteal-c) 100%);
}

.bg-light {
  background: linear-gradient(-45deg, var(--nb-lightteal-a) 0%, var(--nb-lightteal-b) 100%);
}

#main {
  min-height: 100vh;
  color: var(--nb-lightteal-b);
  justify-content: center;
  align-items: center;
  display: flex;
}

#about {
  min-height: 100vh;
  color: var(--nb-teal-b);
  justify-content: center;
  align-items: center;
  display: flex;
}

#services {
  color: var(--nb-lightteal-b);
  justify-content: center;
  align-items: center;
  display: flex;
}

#case {
  color: var(--nb-teal-b);
  justify-content: center;
  align-items: center;
  display: flex;
}

#clients {
  color: var(--nb-lightteal-b);
  justify-content: center;
  align-items: center;
  display: flex;
}

#contact {
  color: var(--nb-teal-b);
  justify-content: center;
  align-items: center;
  display: flex;
}

footer {
  min-height: 300px;
  background: var(--nb-darkteal-a);
  color: var(--nb-lightteal-b);
  justify-content: center;
  align-items: center;
  display: flex;
}

section h2 {
  margin-bottom: 10px;
  font-size: 2em;
}

.container {
  width: 100%;
  max-width: 1180px;
  flex-wrap: wrap;
  padding: 0 20px;
  display: flex;
}

.hero p {
  margin-top: 30px;
}

#about hr.nb-line.dark {
  margin-bottom: 22px;
}

.col-1 {
  width: calc(100% - 30px);
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  display: flex;
}

.col-2 {
  width: calc(50% - 30px);
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  display: flex;
}

.col-2.top {
  justify-content: flex-start;
  margin-bottom: 30px;
}

.col-3 {
  width: calc(33.33% - 30px);
  flex-direction: column;
  padding: 0 15px;
  display: flex;
}

.col-4 {
  width: calc(25% - 30px);
  flex-direction: column;
  padding: 0 15px 15px;
  display: flex;
}

.column-centered {
  text-align: center;
  height: 100%;
  background: #ffffff0a;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding: 30px;
  display: flex;
}

.col-1.center {
  align-items: center;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-40 {
  padding-top: 40px;
}

.mt-0 {
  margin-top: 0;
}

.pt-20 {
  padding-top: 20px;
}

img.section-service-img {
  max-height: 110px !important;
}

img.case-img {
  width: 100%;
}

img.section-client-img {
  width: 100%;
  transition: transform .1s;
}

img.section-client-img:hover {
  transform: scale(1.05);
}

.center button {
  margin: auto;
}

.section-img {
  max-width: 100%;
  padding: 20px;
}

.card {
  background: var(--nb-lightteal-a);
  height: 100%;
  border-radius: 10px;
  padding: 5px 30px;
}

.button.primary.dark {
  border-color: var(--nb-teal-a);
  background: var(--nb-teal-a);
  color: var(--nb-lightteal-b);
}

.button.secondary.dark {
  border-color: var(--nb-teal-a);
  color: var(--nb-lightteal-b);
  background: none;
}

.button.secondary.light {
  border-color: var(--nb-teal-a);
  color: var(--nb-teal-a);
  background: none;
}

.button {
  cursor: pointer;
  border: 2px solid;
  border-radius: 4px;
  margin-right: 20px;
  padding: 16px 30px;
  font-size: .9em;
  font-weight: normal;
  text-decoration: none;
  transition: all 50ms ease-in-out;
}

a.button:hover {
  opacity: .8;
  transition: all 50ms ease-in-out;
}

#case .col-1.top {
  flex-direction: unset;
  flex-wrap: wrap;
  padding-bottom: 30px;
}

.case-img-container {
  width: 40%;
}

#case .case-info {
  box-sizing: border-box;
  align-items: center;
  padding: 30px;
  display: flex;
  width: 60% !important;
}

#case .case-header {
  width: 100%;
  padding-bottom: 20px;
}

.case-info.center {
  text-align: center;
  flex-direction: column;
}

.case-info.center hr.nb-line.dark {
  margin: auto;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden, [hidden] {
  display: none !important;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only.focusable:active, .sr-only.focusable:focus {
  clip: auto;
  height: auto;
  white-space: inherit;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.menu-section {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.burger {
  cursor: pointer;
  z-index: 3;
  flex-direction: column;
  margin-left: 30px;
  display: none;
  position: relative;
}

.bar1, .bar2, .bar3 {
  width: 34px;
  height: 2px;
  background-color: var(--nb-lightteal-a);
  margin: 4px 0;
  transition: all .4s;
}

.change .bar1 {
  transform: translate(0, 10px)rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -11px)rotate(45deg);
}

@media (max-width: 45em) {
  .menu-section {
    display: none;
  }

  .logo {
    margin: auto;
  }

  html {
    font-size: 1em;
    overflow-x: hidden;
  }

  body {
    position: relative;
    overflow-x: hidden;
  }

  .col-2, .col-3, .col-1 {
    width: 100%;
    padding-bottom: 40px;
  }

  .col-4 {
    width: calc(50% - 30px);
    padding: 0 15px 15px;
  }

  .burger {
    display: flex;
  }

  ul.nav {
    z-index: 2;
    background: var(--nb-teal-b);
    width: 320px;
    height: 100vh;
    flex-direction: column;
    margin: 0;
    padding: 100px 20px 0;
    position: absolute;
    top: 0;
    right: -440px;
  }

  ul.nav-active {
    right: 0;
  }

  section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  img.logo-img {
    width: 200px;
  }

  .container {
    padding: 0 10px;
  }

  #main .container {
    flex-wrap: wrap-reverse;
  }

  .button {
    cursor: pointer;
    border: 2px solid;
    border-radius: 4px;
    margin-right: 10px;
    padding: 10px 20px;
    font-size: .8em;
    transition: all 50ms ease-in-out;
  }

  #case .col-1.top {
    flex-direction: column;
  }

  .case-img-container {
    width: 100%;
  }

  #case .case-info {
    box-sizing: border-box;
    padding: 0;
    width: 100% !important;
  }
}

@media (max-width: 32em) {
  .col-4 {
    width: 100%;
    padding: 0 15px 15px;
  }
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

/*# sourceMappingURL=index.d0fc92e4.css.map */
